/* You can add global styles to this file, and also import other style files */
@import "~css-star-rating/css/star-rating.min.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.cursor {
  cursor: pointer;
}
/*

====================================================================
                      Login-css-start
====================================================================
*/

.login-background-image {
  max-width: 100%;
  background-color: #068466;
  /* background-image: url("~src/assets/images/login/login-bg.png"); */
  background-size: cover;
  position: relative;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
}

.login-card-main-section .card {
  width: 415px;
  /* height: 545px; */
  background: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 16px;
}

.login_header_img {
  width: 85px;
}

.login-heading h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 104%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #2b3244;
  margin: 4px 0 6px 0;
}

.login-heading p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 104%;
  text-align: center;
  color: #75809c;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-form-field {
  width: 100%;
}

.login-btn-continue .btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #3787ff;
  border-radius: 28px;
  width: 100%;
  padding: 10px;
}

.forgot_passoword a {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 142%;
  text-align: center;
  color: #2b3244;
  cursor: pointer;
  text-decoration: none;
}

.forgot_header_img {
  width: 100px;
}

.password_header_img {
  width: 145px;
}

.forgot-heading h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #1a264e;
}

.forgot-heading p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #75809c;
}

.forgot-card-main-section .card {
  width: 420px;
  /* height: 482px; */
  background: #ffffff;
  border-radius: 20px;
  border: none;
  padding: 16px;
}

.forgot-card-body {
  padding: 14px 12px;
}

.forgot-card-main-section .otp-card {
  width: 440px !important;
  /* height: 523px !important; */
}

.ngx-otp-input {
  width: 50px !important;
  height: 40px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #e1e7f7 !important;
  text-align: center !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-size: 32px;
}

.ngx-otp-input:invalid {
  border-bottom: 1px solid #ff0000 !important;
}

.otp-input {
  width: 60px !important;
  height: 50px;
  border-radius: 0px !important;
  border-bottom: 1px solid #e1e7f7 !important;
  text-align: center;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-size: 32px;
}

/* .otp-input:focus {
  outline-style: solid;
  outline-color: red !important;
  border-bottom: 1px solid #ff0000 !important;
} */

/* .otp-input:valid {
  border-bottom: 1px solid #ff0000 !important;
} */

.resend_btn h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #75809c;
}

.resend_btn h5 button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #1091ff !important;
  border: none;
  background-color: #ffffff;
}

.counter {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #2b3244;
}

.counter_img {
  margin: -2px 0 0 0;
}

.forgot-card-main-section .password-card {
  width: 482px !important;
  /* height: 540px !important; */
}

.success p {
  /* width: 350px; */
  text-align: center;
  margin: 0 auto;
}

.forgot-card-main-section .success-card {
  width: 430px;
  /* height: 446px; */
}

.Back-to-login .btn {
  width: 84%;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
  display: flex;
}

/*

====================================================================
                      Login-css-END
====================================================================
*/

/*

====================================================================
                      customers-css-start
====================================================================
*/
.customer_header h1 {
  font: normal normal 600 32px/32px "Poppins" !important;
  color: #000000;
  text-align: center;
  margin: 12px 6px;
}
.action {
  background: #b1b4b4 0% 0% no-repeat padding-box;
  color: white;
  width: auto;
  text-align: center;
  padding: 7px;
}
.card-section .card h3 {
  margin: 0;
}
.month_trail img {
  border-radius: 7px;
  width: 41px;
  background: white;
  padding: 7px;
  margin: 6px 0;
}
.month_trail ul {
  display: flex;
  background-color: #068466;
  list-style-type: none;
  padding: 6px 23px;
}
.trail_header h5 {
  font: normal normal 500 25px/51px "Poppins" !important;
  color: #000000;
  text-align: left;
  margin: 0;
}
.month_trail li {
  font: normal normal 400 16px/50px "Poppins" !important;
  color: #ffffff;
  text-align: center;
}
select:focus-visible {
  background-color: #ffffff !important;
}
/*

====================================================================
              customers-css-END
====================================================================
*/
.main {
  overflow: hidden;
}
.myPanelDonationrole {
  margin-top: 30px !important;
  margin-left: -7px !important;
}

.users-count {
  border: 0.5px solid #edf1fa;
  border-radius: 12px;
  width: 100%;
}
.users-count .card-body {
  background: #068466 0% 0% no-repeat padding-box;
  border: 1px solid #cdcdcd;
  border-radius: 15px;
}
.users-count .card-body {
  padding: 7px 18px;
}
.card-details-users h4 {
  font: normal normal 600 14px/20px "Poppins" !important;
  color: #ffffff;
  text-align: center;
}
.card-details-users h2 {
  font: normal normal 600 21px/20px "Poppins" !important;
  color: #ffffff;
  text-align: center;
}
.not-eligable {
  color: #ffa12e !important;
  background: #fff3e5;
  border-radius: 15px;
  text-align: center;
  padding: 5px 10px;
}
.not-member {
  color: #ff4242 !important;
  background: #ffecec;
  border-radius: 15px;
  text-align: center;
  padding: 5px 10px;
}
.rejected {
  color: #ff4342 !important;
  background: #ffeceb;
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
  /* width: 50%; */
}
